<template>
    <footer class="footer">
        <div class="content is-size-7 has-text-left">
            <ul>
                <li><router-link to="/about-us">About us </router-link> </li>
                <li><router-link to="/contact-us">Contact us</router-link></li>
                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                <li><router-link to="/terms-of-service">Terms and Conditions</router-link></li>
                <li><router-link to="/refund-policy">Refund Policy</router-link></li>
            </ul>
        </div>
    </footer>

</template>

<script>
export default {
    
}
</script>