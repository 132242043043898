<template>
    <div>
        <nav-bar ref="navbar"></nav-bar>
        <div class="columns py-6 is-centered is-half">
            <div class="content">
                <p>Order failed and cancelled</p>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'

export default {
    data: function() {
        return {
        }
    },
    components: {
        NavBar
    },
    created: async function() {
    }
}
</script>

