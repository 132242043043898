<template>
    <div>
         
        <div class="columns py-6 is-centered is-flex">
            <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
            <input type="hidden" name="key_id" :value="razorKey">
            <input type="hidden" name="amount" :value="amount">
            <input type="hidden" name="order_id" :value="razor_order_id">
            <input type="hidden" name="name" value="HHZ">
            <input type="hidden" name="prefill[contact]" :value="phone">
            <input type="hidden" name="prefill[email]" :value="email">
            <input type="hidden" name="callback_url" :value="serverUrl +'/payCallback'">
            <input type="hidden" name="cancel_url" :value="serverUrl +'/paycancel'">
            <button class="button is-primary" >Pay</button> 
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            razorKey: "",
            serverUrl: ""
        }
    },
    mounted: function(){
        this.razorKey =  process.env.VUE_APP_RAZOR_KEY;
        this.serverUrl =  process.env.VUE_APP_SERVER;
    },
    props: ['amount','razor_order_id','woo_order_id', 'phone', 'email'],
}
</script>