<template>
  <!-- <transition appear name="glitchOverlay"  v-on:appear="glitchEnter" v-on:after-appear="glitchLeave" v-bind:css="false">
    <div>
    <video autoplay muted loop id="transition_video">
      <source src="/assets/glitch_overlay.mp4" type="video/mp4">
    </video>

    </div>
  </transition> -->
    <router-view/>
</template>

<script>

export default {
  name: 'App',
  data: function(){
    return {
      play: false
    }
  },
  components: {
    
  },
  methods: {
    // glitchEnter: function(el, done){
    //   console.log("EYPO")
    //   this.play = true;
    //   var media = document.createElement("VIDEO");
    //   media.className = 'transition_video';
    //   media.src = "/assets/glitch_overlay.mp4";
    //   media.autoplay = true;
    //   document.body.append(media);
    //   // const media = document.querySelector('video');
    //   // media.play();
    //   setTimeout(function() {
    //     done();
    //     console.log("WEDONE")
    //   }, 2000)
    // },
    // glitchLeave: function(el, done){
    //   this.play = false;
    //   done

    // }
  }
}
</script>

<style lang="css">
#transition_video {
  position: fixed;
  
  z-index: 10;
  right: 0;
  bottom: 0;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}

/* .glitchOverlay-enter-active {
  transition: all 2s ease;
}
.glitchOverlay-leave-active {
  transition: all 2s ease;
} */
</style>
<style lang="scss">
   @import 'hhzstyles.scss';
</style>


