import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Collection from '../views/Collection.vue'
import Product from '../views/Product.vue'
import Checkout from '../views/Checkout.vue'
import Pay from '../views/Pay.vue'
import PaySuccess from '../views/PaySuccess.vue'
import AboutUs from '../views/policies/AboutUs.vue'
import ContactUs from '../views/policies/ContactUs.vue'
import PrivacyPolicy from '../views/policies/PrivacyPolicy.vue'
import TOS from '../views/policies/TOS.vue'
import Refund from '../views/policies/Refund.vue'
import PayCancel from '../views/PayCancel.vue'
import SizeChart from '../views/SizeChart.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/collection', 
    name: 'collection', 
    component: Collection 
  },
  {
    path: '/about-us',
    name: 'aboutus',
    component: AboutUs
  },
  {
    path: '/contact-us',
    name: 'contactus',
    component: ContactUs
  },
  {
    path: '/terms-of-service',
    name: 'termsofservice',
    component: TOS
  },
  {
    path: '/refund-policy',
    name: 'refundpolicy',
    component: Refund
  },
  {
    path: '/collection/:id',
    name: 'product',
    component: Product,
    props: true
  },
  {
    path: '/paysuccess/:id',
    name: 'paysuccess',
    component: PaySuccess,
    props: true
  },
  {
    path: '/size-chart',
    name: 'sizechart',
    component: SizeChart
  },
  {
    path: '/paycancel',
    name: 'paycancel',
    component: PayCancel
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    props: true
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,

  },
  {
    path: '/pay',
    name: "pay",
    component: Pay,
    props:true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
