<template>
    <div>
        <nav-bar ref="navbar"></nav-bar>
        <div class="columns py-6 is-centered is-half">
            <div class="content">
                <p>Order {{id}} successful. Confirmation email will be sent shortly</p>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../Api.js'
import NavBar from '../components/NavBar.vue'

export default {
    props: ['id'],
    data: function() {
        return {
        }
    },
    components: {
        NavBar
    },
    created: async function() {
        var order = await api.getOrder(this.id);
        console.log(order);
    }
}
</script>

