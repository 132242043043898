<template>
    <div>
        <nav-bar ref="navbar"></nav-bar>
        <section class="section">
            <div class="containter px-6">
                <div class="columns is-multiline"> 
                    <div class="column is-half is-offset-one-quarter mb-6">
                        <div class="content">
                            <p class="title has-text-centered glitch" data-text="CHECKOUT">CHECKOUT</p>
                        </div>
                        <!-- Covid delay info -->
                        <article class="message is-warning">
                            <div class="message-header">
                                <p>Shipping may take 10+ day due to COVID-19</p> 
                            </div>
                        </article>
                    </div>
                    <!-- List of items in cart -->
                    <div class="column is-half">
                        <p class="subtitle has-text-centered has-text-weight-semibold">CART</p>
                        <div v-if="items">
                            <article class="media" v-for="item in items" :key="item.id">
                                <figure class="media-left image is-128x128">
                                    <img v-bind:src="item.images[0].src">
                                </figure>
                                <div class="media-content">
                                    <div class="content has-text-centered">
                                        {{item.name}}
                                        <br>
                                        <p class="has-text-weight-semibold">₹{{item.price}}</p>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <button class="button is-radiusless" v-on:click="removeFromCart(item.id)"><span class="icon"><i class="fas fa-times"></i></span></button>
                                </div>
                            </article>
                        </div>
                    </div>

                    <!-- Checkout data -->
                    <div class="column is-half">
                        <p class="subtitle has-text-centered has-text-weight-semibold">SHIPPING</p>

                        <p v-if="errors.length">
                            <b>Please correct the following error(s):</b>
                            <ul>
                                <li class="help is-danger" v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </p>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"> Email </label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input v-model="email" class="input" type="email" placeholder="Email">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"> Phone </label>
                            </div>
                            <div class="field-body">
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button is-static">
                                            +91
                                        </a>
                                    </p>
                                    <p class="control is-expanded">
                                        <input v-model="phone" class="input" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Phone Number">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Name</label>
                            </div>

                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input v-model="firstName" class="input" type="text" placeholder="First Name">
                                    </p>
                                </div>
                                <div class="field">
                                    <p class="control">
                                        <input v-model="lastName" class="input" type="text" placeholder="Last Name">
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Address</label>
                            </div>

                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input v-model="address_1" class="input" type="text" placeholder="Address line 1">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label"></div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input v-model="address_2" class="input" type="text" placeholder="Address line 2">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"> City </label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input v-model="city" class="input" type="text" placeholder="City">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"> State </label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input v-model="state" class="input" type="text" placeholder="State">
                                    </p>
                                </div>
                                <div class="field">
                                    <p class="control">
                                        <input v-model="pinCode" class="input" type="number" placeholder="PIN Code">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- different billing address -->

                        <!-- <div class="field is-horizontal">
                            <div class="field-label">
                            </div>
                            <div class="field-body">
                                <div class="control">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="differentBilling">
                                        Different Billing Address
                                    </label>
                                </div>
                            </div>
                        </div> -->

                        <div v-if="differentBilling">
                            <p class="subtitle has-text-centered has-text-weight-semibold">BILLING</p>
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Name</label>
                                </div>

                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.first_name" class="input" type="text" placeholder="First Name">
                                        </p>
                                    </div>
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.last_name" class="input" type="text" placeholder="Last Name">
                                        </p>
                                    </div>

                                </div>
                            </div>

                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Address</label>
                                </div>

                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.address_1" class="input" type="text" placeholder="Address line 1">
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="field is-horizontal">
                                <div class="field-label"></div>
                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.address_2" class="input" type="text" placeholder="Address line 2">
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label"> City </label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.city" class="input" type="text" placeholder="City">
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label"> State </label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.state" class="input" type="text" placeholder="State">
                                        </p>
                                    </div>
                                    <div class="field">
                                        <p class="control">
                                            <input v-model="billing.postcode" class="input" type="number" placeholder="PIN Code">
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="field is-horizontal mt-2">
                            <div class="field-label">
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <button class="button is-primary" @click="sendOrder">Order</button> 
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import api from '../Api.js'
import store from '../components/Store.js'
import NavBar from '../components/NavBar.vue'
import router from '../router'

export default {
    data: function() {
        return {
            items: [

            ],
            billing: {
                first_name: '',
                last_name: '',
                address_1: '',
                address_2: '',
                email: '',
                phone: '',
                city: '',
                state: '',
                postcode: '',
                country: '',
            },
            firstName: '',
            lastName: '',
            address_1: '',
            address_2: '',
            email: '',
            phone: '',
            city: '',
            state: '',
            pinCode: '',
            differentBilling: false,
            razorKey: "",
            order: '',
            errors: []
        }
    },

    components:{
        NavBar
    },

    beforeCreate: async function() {
        const cart = store.getCart().items;
        for(let item of cart){

            const i = await api.get(item.product_id);

            this.items.push(i);
        }
        this.razorKey =  process.env.VUE_APP_RAZOR_KEY;
    },

    methods: {
        removeFromCart: function(id){
            this.$refs.navbar.remove_from_cart(id);
            const index = this.items.findIndex((e) => e.id == id);
            this.items.splice(index,1);
        },

        sendOrder: async function(){
            if(this.checkForm()){
                var shipping = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    state: this.state,
                    address_1: this.address_1,
                    address_2: this.address_2,
                    city: this.city,
                    phone: this.phone,
                    postcode: this.pinCode,
                    country: 'IN'
                }

                this.billing.country = 'IN';
                this.billing.email = this.email;
                this.billing.phone = this.phone;

                var billing = shipping;
                if(this.differentBilling)
                    billing = this.billing;
                var cart = store.getCart().items
                console.log(shipping);
                console.log(billing);

                const order = await api.makeOrder(cart, billing, shipping)
                this.order = order;
                store.emptyCart();
                router.push({name: 'pay', params: {
                    amount: order.total * 100, 
                    razor_order_id: order.razor_order, 
                    woo_order_id: order.id,
                    phone: this.phone,
                    email: this.email
                }})
            }
            
        },
        checkForm: function () {
            this.errors = [];

            if (!this.firstName) {
                this.errors.push("Name required.");
            }
            if (!this.email) {
                this.errors.push('Email required.');
            } 
            if (!this.phone) {
                this.errors.push('Phone required.');
            } 
            if (!this.city) {
                this.errors.push('City required.');
            } 
            if (!this.state) {
                this.errors.push('State required.');
            } 
            if (!this.pinCode) {
                this.errors.push('PIN required.');
            } 
            if(this.phone.toString().length > 10 || this.phone.toString().length < 10) {
                this.errors.push('Phone number not correct length');
            } 
            if (!this.address_1) {
                this.errors.push('Address required.');
            } 
            if (!this.errors.length) {
                return true;
            }else {
                return false;
            }

        },
        

    }
}
</script>

