import axios from 'axios'

const SERVER_URL = process.env.VUE_APP_SERVER;

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 10000
});

export default {
    getAll: async function(){
        const response = await instance.get('/products');
        return response.data
    },
    get: async function(id){
        const response = await instance.get('/products/'+id);
        return response.data
    },
    getVariation: async function(prodId, variationId){
        const response = await instance.get('/products/'+ prodId +"/variations/"+ variationId);
        return response.data
    },
    makeOrder: async function(items, billing, shipping){
        const response = await instance.post('/orders', {
            billing: billing,
            shipping: shipping,
            line_items: items
        });
        console.log(response);
        return { 
            order_key: response.data.order_key, 
            id: response.data.id,
            razor_order: response.data.razor_order,
            total: response.data.total 
        };
    },

    getOrder: async function(id){
        const response = await instance.get('/orders/'+id);
        return response.data;
    },

    updateOrder: async function(id, order){
        const response = await instance.post('/orders/'+id,order);
        return response.data;
    }
}