<template>
  <div id="app">
    <section class="hero is-fullheight bg-img">
      <div class="hero-head">
        <nav-bar ref="navbar"></nav-bar>
      </div>
      
      <div class="hero-body">
        <video autoplay muted loop id="backgroundVideo">
          <source src="../assets/city_ascii.mp4" type="video/mp4">
        </video>
        <div class="container">

          <div class="columns is-centered is-mobile">
            <div class="column is-one-quarter-desktop is-half-mobile ">
              <img id="logo" src="../assets/logo.png">
              <a href="/collection">
              <p class="title is-size-3 is-size-6-mobile glitch has-text-centered"  data-text="HELLOWORLD OUTNOW">
                HELLOWORLD OUTNOW
              </p>
              </a> 
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <section class="hero is-medium" id="bottomHero">
      <div class="hero-body">
        <div class="container">
          <p class="title has-text-right glitch" data-text="ENTER THE HACKERVERSE. SHIRTS ON SALE NOW" >
              ENTER THE HACKERVERSE. SHIRTS ON SALE NOW
          </p>

        </div>
      </div>
      <div class="hero-footer"> 

      </div>
    </section>
        <site-footer></site-footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import NavBar from '../components/NavBar.vue'
import SiteFooter from '../components/SiteFooter.vue'

export default {
  name: 'Home',
  components: {
    NavBar,
    SiteFooter
  }
}
</script>

<style>
/* .bg-img {
  background-image: url("../assets/bg.jpg");
  background-size: cover;
} */
#backgroundVideo {
  position: absolute;
  
  z-index: -100;
  right: 0;
  bottom: 0;
  object-fit: cover;
  min-width: 120%;
  min-height: 120%;
}

#bottomHero {
  background-image: url("../assets/pattern1.png");
  background-repeat: repeat-x;
  background-position-y: center;
}

#logo {
  z-index: -3;
}
</style>