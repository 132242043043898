<template>
    <div>
        <nav-bar></nav-bar>
        <section class="section">
            <div class="columns is-centered">
                <div class="column is-half">
                    <div class="content has-text-centered">
                        <div class="title">About Us</div>
                        <p class="is-family-monospace">HHZ offers fashion for the hacker and cyberpunk culture. Glitch art, cyberpunk styles. Designed by Husain Zaidi</p>
                        <p class="is-family-monospace">Fonts: <br>"Hacked" and its author "David Libeau".<br> Kernel Panic NBP" and its author "FontGeek DTF, Ltd."</p>
                    </div>
                </div>
            </div>
        </section>
        <site-footer></site-footer>
    </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue'
import SiteFooter from '../../components/SiteFooter.vue'

export default {

    data: function() {
        return {

        }
    },
    components: {
        NavBar,
        SiteFooter
    }

}
</script>
