<template>
    <div>
        <nav-bar ref="navbar"></nav-bar>
        <section class="section">
            
            <div class="columns is-centered is-multiline"> 
                <div class="column is-half">
                    <figure class="image ">
                        <img src="../assets/Men_tshirt.jpg">
                    </figure>
                    <figure class="image ">
                        <img src="../assets/Men_Full_Sleeve.jpg">
                    </figure>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
export default {
    
    data: function(){
        return {

        }
    },

    components:{
        NavBar
    }
    
}
</script>