<template>
    <div>
        <nav-bar></nav-bar>
        <section class="section">
            <div class="columns is-centered">
                <div class="column is-half">
                    <div class="content has-text-centered">
                        <div class="title">Contact Us</div>
                        <p>Send us feedback, ideas. Contact us for assistance with any issue</p>
                        <p class="is-family-monospace">Email: husainhz7@gmail.com</p>
                    </div>
                </div>
            </div>
        </section>
        <site-footer></site-footer>
    </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue'
import SiteFooter from '../../components/SiteFooter.vue'

export default {

    data: function() {
        return {

        }
    },
    components: {
        NavBar,
        SiteFooter
    }

}
</script>
