<template>
    <nav class="navbar is-transparent" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
            <h1 class="navbar-item has-text-weight-bold"><a href="/">HHZ</a></h1>
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarItems">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
            </div>
        
            <div id="navbarItems" class="navbar-menu">
            <div class="navbar-end">
                <a class="navbar-item glitch-hover" data-glitch="Home" href="/">Home</a>
                <router-link class="navbar-item glitch-hover" data-glitch="Collection" to="/collection">Collection</router-link>
                <router-link class="navbar-item glitch-hover" data-glitch="Feedback" to="/contact-us">Feedback</router-link>
                <div class="navbar-item glitch-hover">
                    
                    <router-link to="/checkout">
                    <span class="icon">
                        <i class="fa fa-cart-arrow-down"></i>
                        <span class="pl-2">{{cartSize}}</span>
                    </span>
                    </router-link>
                </div>
            </div>
            
            </div>
        </div>
    </nav>
</template>

<script>
import store from '../components/Store.js'
export default {
    data: function() {
        return{
            cartSize: 0,
        }
    },
    created: function(){
        this.cartSize = store.getCart().items.length;
    },
    mounted: function(){
        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {

            // Add a click event on each of them
            $navbarBurgers.forEach( el => {
                el.addEventListener('click', () => {

                    // Get the target from the "data-target" attribute
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);

                    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                    el.classList.toggle('is-active');
                    $target.classList.toggle('is-active');

                });
            });
        }
    },

    methods: {
        add_to_cart(id, variationId) {
            store.addToCart(id, variationId);
            this.cartSize++;
        },
        remove_from_cart(id){
            store.removeFromCart(id);
            this.cartSize--;
        }
    }
}
</script>