<template>
    <div>
        <nav-bar ref="navbar"></nav-bar>
        <section class="hero is-medium">
            <div class="hero-body" id="collectionHeader">
                <div class="container">
                <p class="title has-text-centered glitch" data-text="Hello World Collection">
                    Hello World collection
                </p>
                </div>
            </div>
        </section>
        <div class="columns is-centered is-mobile"> 
            <div class="column is-half">
                <div class="columns is-multiline" v-if="clothes">
                    
                    <div class="column is-one-third"  v-for="cloth in clothes" :key="cloth.name">
                        <a v-bind:href="'/collection/'+cloth.id">
                            <figure class="image is-square">
                                <img v-bind:src="cloth.images[0].src">
                            </figure>

                            <div class="box has-text-centered is-shadowless">
                                <p class="is-size-6">{{cloth.name}}</p>
                                <p class="has-text-weight-semibold">₹{{cloth.price}}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <site-footer></site-footer>
    </div>
</template>

<script>
import api from "../Api.js"
import NavBar from '../components/NavBar.vue'
import SiteFooter from '../components/SiteFooter.vue'
export default {
    name: "Collection",
    data: function() {
        return {
            clothes: [ 
            ],
        }
    },
    components: {
        NavBar,
        SiteFooter 
    },
    beforeCreate: async function(){
        const data = await api.getAll() 
        this.clothes = data;
    }
}
</script>

<style lang="css">
    #collectionHeader {
        background-image: url('../assets/pattern1.png');
        background-repeat: repeat-x ;
        background-position-y: center;
    }
</style>