<template>
    <div>
        <nav-bar ref="navbar"></nav-bar>
        <section class="section">
           
            <div class="columns is-centered mt-6"> 
                <div class="column is-three-quarters">
                    <div class="columns" v-if="cloth">
                        <div class="column ">
                            <carousel :perPage='1' :navigationEnabled='true'>
                                <slide v-for="image in cloth.images" :key="image.id">
                                    <figure class="image is-square">
                                        <img v-bind:src="image.src">
                                    </figure>
                                </slide>
                            </carousel>
                        </div>
                        <div class="column ">
                            <div class="content">
                                <h1 class="has-text-right">{{cloth.name}} </h1>
                                <h2 class="has-text-right">₹{{cloth.price}} </h2>
                                <h5 class="has-text-right has-text-weight-light" id="sale">₹{{parseInt(cloth.price)+100}}</h5>
                                <div class="has-text-right py-6">{{cloth.short_description}}</div>
                                <div class="field is-horizontal">
                                    <label class="field-label">Color</label>
                                    <div class="control">
                                        <div class="select">
                                        <select v-model="color">
                                            <option v-for="s in cloth.attributes[0].options" :key="s">{{s}}</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <label class="field-label">Size</label>
                                    <div class="control">
                                        <div class="select">
                                        <select v-model="size" >
                                            <option v-for="s in cloth.attributes[1].options" :key="s">{{s}}</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="field is-horizontal">
                                    <label class="field-label"><a href="/size-chart">Size Chart</a></label>
                                </div>
                                 <div class="field is-horizontal">
                                    <label class="field-label"></label>
                                    <div class="control">
                                        <button class="button outlined" v-on:click="addToCart">Add to Cart</button>
                                    </div>
                                 </div>
                                <article class="message is-success" v-if="addedToCart">
                                    <div class="message-header">
                                        <p>Item added to cart</p> 
                                    </div>

                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import api from '../Api.js'
import NavBar from '../components/NavBar.vue'
import { Carousel, Slide } from 'vue-carousel';
import {mixpanel} from '../main.js'

export default {

    props:['id'],
    data: function(){
        return{
            cloth: {name:'dd'},
            color: '',
            size: '',
            addedToCart: false,
        }   
    },

    created: async function(){
        const data = await api.get(this.id);
        this.cloth = data;
        mixpanel.track("Opened Product", {"product": this.cloth.name})

    },

    components:{
        NavBar,
        Carousel,
        Slide
    },

    methods:{
        addToCart: async function(){
            //get all variations
            var variationId = this.cloth.variations[0];
            for (let i = 0; i < this.cloth.variations.length; i++) {
                
                const product = await api.getVariation(this.id, this.cloth.variations[i]);
                if(product.attributes[0].option == this.color && product.attributes[1].option == this.size){
                    variationId = this.cloth.variations[i]
                    
                    break;
                }
                
            }
            mixpanel.track("Add to Cart", {"product": this.cloth.name, "color": this.color, "size": this.size})
            this.$refs.navbar.add_to_cart(this.cloth.id, variationId);
            this.addedToCart = true;
        },

    },

}
</script>
<style lang="css">
#sale {

  text-decoration: line-through;

}
</style>