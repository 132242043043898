

var store = {
    state:{
        cart: []
    },


    addToCart (id, variationId) {
        
        if(!localStorage.getItem('Cart') || !localStorage.getItem('Cart').items){
            const itemObject = {items:[{ product_id: id, variation_id: variationId, quantity: 1}]}
            localStorage.setItem('Cart', JSON.stringify(itemObject) )
        }else {
            var itemObject = localStorage.getItem('Cart');
            itemObject = JSON.parse(itemObject);
            itemObject.items.push({ product_id: id, variation_id: variationId, quantity: 1});
            localStorage.setItem('Cart', JSON.stringify(itemObject))
        }
    },

    getCart (){
        if(localStorage.getItem('Cart'))
            return JSON.parse(localStorage.getItem('Cart'));
        else
            return{items: []};
    },

    removeFromCart(id) {
        if(localStorage.getItem('Cart')){
            var cart = JSON.parse(localStorage.getItem('Cart'));
            const index = cart.items.findIndex((e) => e.product_id == id);
            cart.items.splice(index,1);
            localStorage.setItem('Cart', JSON.stringify(cart))
        }else {
            throw new Error('Cart Already Empty');
        }

    },

    emptyCart() {
        if(localStorage.getItem('Cart')){
            localStorage.removeItem('Cart')

        } else {
            throw new Error('Cart Already Empty');
        }
    }


}

export default store;