import Vue from 'vue'

import App from './App.vue'
import router from './router'
export var mixpanel = require('mixpanel-browser');

mixpanel.init("e25a63b389188419709fa7487fadd394");

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')



